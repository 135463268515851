import React, { useContext, useState, useEffect } from 'react';
import styles from './Profile.module.css';
import { FaAngleDown } from 'react-icons/fa6';
import productContext from '../Context/ProductContext/ProductContext';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import HashLoader from "react-spinners/HashLoader";


const Profile = () => {
  const [loading, setLoading] = useState(true);
  // const [orderAccept, setOrderAccept] = useState(true);


  const auth = useContext(productContext);
  const url = auth.url;
  // console.log(url)
  useEffect(() => {
    const fetchAddress = () => {
      auth.fetchUserAddress();
      setLoading(false);
    }
    fetchAddress();
  }, []);
  // console.log(auth.address)
  const userAddress = auth.address;
  const [activeAdd, setActiveAdd] = useState(false);
  const [activeOrder, setActiveOrder] = useState(false);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [orderList, setOrderList] = useState([]);
  // const [userAddress, setUserAddress] = useState({});
  const [address, setAddress] = useState({
    FullName: '',
    Add: '',
    VillorCity: '',
    Dist: '',
    State: '',
    Pin: '',
    Phone: ''
  });

  const [error, setError] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;

  // Validation for Phone
  if (name === "Phone") {
    if (value.length > 10 ) {
      setError("Phone number must be 10 digits.");
      return;
    }
    if (!/^\d*$/.test(value)) {
      setError("Only numeric values are allowed.");
      return;
    }
    setError("");  
  }

    setAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value
    }));
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  
  //   if (address.Phone.length !== 10) {
  //     setError("Phone number must be exactly 10 digits.");
  //     return;
  //   }
  
  //   setError(""); 
  //   console.log("Form submitted:", address);
  // };

  const reload = () => {
    window.location.reload();
  }
  const navigate = useNavigate();

  // console.log(userAddress)


  // Function for Logout


  const logOut = () => {
    auth.setAuthinticated('');
    localStorage.removeItem('token');
    localStorage.removeItem('userName');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('userId');
    navigate('/login');
    auth.setAddress("");
  };

  const handleOrderRedirect = () => {
    window.scrollTo(0, 0);
    navigate('/order'); // Assuming '/orders' is the path for the orders page
  };


  const toggleAddress = () => {
    setActiveAdd(!activeAdd);
  };

  const toggleOrder = async () => {
    setActiveOrder(!activeOrder);

    try {
      setLoading(true)
      const orders = await axios.get(`${url}/api/payment/getOrderById`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Auth': auth.authinticated
        }
      })
      // console.log(orders.data)
      // setOrderAccept(true)

      const orderData = orders.data;
      const allOrderedItem = orderData.flatMap(orderData => orderData.orderItems);

      setLoading(false)
      // console.log(allOrderedItem);
      setOrderList(allOrderedItem)
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    setUserName(localStorage.getItem('userName') || '');
    setUserEmail(localStorage.getItem('userEmail') || '');
    // setUserAddress(auth.address)
    // console.log(userAddress);
  }, []);



  // Function to add address
  const addAddress = async (e) => {
    e.preventDefault();
    // Implement your form submission logic here
    // console.log('Form submitted', address);
    try {
      // console.log(address)

      const responce = await axios.post(`${url}/api/address/addAddress`, address, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Auth': auth.authinticated
        }
      });
      setErrorMessage(responce.data.message);
      console.log(responce.data.message);
      reload();
    } catch (error) {
      console.error('Error submitting Address:', error.message);
    }
  };



  // function to Edit address
  const editAddress = async (e) => {
    e.preventDefault();
    // Implement your form submission logic here
    // console.log('Form submitted', address);
    try {
      const addressId = auth.address._id;

      const responce = await axios.put(`${url}/api/address/updateAddress/${addressId}`, address, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Auth': auth.authinticated
        }
      });
      setErrorMessage(responce.data.message)
      console.log(responce);
      window.location.reload();
    } catch (error) {
      console.error('Error Editing Address:', error.message);
    }
  };
  // console.log(orderList);
  return (
    <div>
      {loading ? (
        <div className={styles.loaderContainer}>
          <HashLoader color={"#009578"} loading={loading} size={58} />
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.profileDetails}>
            <h2>Welcome {userName}</h2>
            <h3>Email: {userEmail}</h3>
            <button onClick={logOut} className={styles.logOutBtn}>
              Log Out
            </button>
          </div>
          <div onClick={toggleAddress} className={styles.address}>
            <h2>Address</h2>
            <FaAngleDown />
          </div>
          {activeAdd && (
            <div className={styles.openAddress}>
              {userAddress && (
                <div className={styles.addressDiv}>
                  <div className={styles.fullAddress}>
                    <h3 className={styles.addressh3}>Address : </h3>
                    <p className={styles.addressp}>{userAddress.Add}</p>
                  </div>
                  <div className={styles.sepAddressDiv}>
                    <div className={styles.sepAddress}>
                      <h3>Full Name : </h3>
                      <p>{userAddress.FullName}</p>
                    </div>
                    <div className={styles.sepAddress}>
                      <h3>Vill/City : </h3>
                      <p>{userAddress.VillorCity}</p>
                    </div>
                    <div className={styles.sepAddress}>
                      <h3>Dist : </h3>
                      <p>{userAddress.Dist}</p>
                    </div>
                    <div className={styles.sepAddress}>
                      <h3>State : </h3>
                      <p>{userAddress.State}</p>
                    </div>
                    <div className={styles.sepAddress}>
                      <h3>Pin : </h3>
                      <p>{userAddress.Pin}</p>
                    </div>
                    <div className={styles.sepAddress}>
                      <h3>Phone : </h3>
                      <p>{userAddress.Phone}</p>
                    </div>
                  </div>
                </div>
              )}
              <div className={styles.productForm}>
                <h2>{userAddress ? "Edit Address" : "Add New Address"}</h2>
                <form onSubmit={userAddress ? editAddress : addAddress}>
                  <div className={styles.formGroup}>
                    <label>Full Name: </label>
                    <input
                      type="text"
                      name="FullName"
                      value={address.FullName}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className={styles.formGroup}>
                    <label>Address:</label>
                    <textarea
                      name="Add"
                      value={address.Add}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className={styles.formGroup}>
                    <label>Vill/City:</label>
                    <input
                      type="text"
                      name="VillorCity"
                      value={address.VillorCity}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className={styles.formGroup}>
                    <label>Dist:</label>
                    <input
                      type="text"
                      name="Dist"
                      value={address.Dist}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className={styles.formGroup}>
                    <label>State:</label>
                    <input
                      type="text"
                      name="State"
                      value={address.State}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className={styles.formGroup}>
                    <label>Pin:</label>
                    <input
                      type="text"
                      name="Pin"
                      value={address.Pin}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className={styles.formGroup}>
                    <label htmlFor="phone">Phone:</label>
                    <input
                      id="phone"
                      type="text"
                      name="Phone"
                      value={address.Phone}
                      onChange={handleChange}
                      required
                    />
                    {error && <p className={styles.errorText}>{error}</p>}
                  </div>
                  <button type="submit" className={styles.submitButton}>
                    {userAddress ? "Edit" : " Submit"}
                  </button>
                  <div className={styles.errorMessage}>
                    <p>{errorMessage}</p>
                  </div>
                </form>
              </div>
            </div>
          )}
          <div onClick={handleOrderRedirect} className={styles.orders}>
            <h2>Orders</h2>
            <FaAngleDown />
          </div>
          {/* {activeOrder && (
            <div className={styles.openOrder}>
              <div className={styles.allOrder}>

                {
                  orderList.map((elem) => {
                    const { imgSrc, price, qty, title, _id } = elem
                    return (
                      <div key={_id} className={styles.productDiv}>
                        <img
                          className={styles.productImg}
                          src={imgSrc}
                          alt="product"
                        />
                        <div className={styles.productDetails}>
                          <div className={styles.productName}>
                            <p>{title}</p>
                          </div>
                          <div className={styles.quantity}>
                            <p className={styles.p}>Quantity: {qty}</p>
                          </div>
                          <div className={styles.quantity}>
                            <p className={styles.p}>Price: {price}</p>
                          </div>
                          <div className={styles.quantity}>

                            <p className={styles.p}>Order will be delivered in 7 days</p>

                          </div>
                        </div>
                      </div>
                    )
                  })
                }


              </div>
            </div>
          )} */}
          <div className={styles.editProfile}>
            <h2>Edit Profile</h2>
            <FaAngleDown />
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;