import React, { useContext, useEffect, useState, useCallback } from 'react';
import styles from "./HotSells.module.css";
import { useNavigate } from 'react-router-dom';
import productContext from '../Context/ProductContext/ProductContext';
import HashLoader from "react-spinners/HashLoader";

const HotSells = () => {
    const { fetchProducts, products } = useContext(productContext);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            await fetchProducts();
            setLoading(false);
        };
        fetchData();
    }, []);

    const handleNavigation = useCallback((id) => {
        window.scrollTo(0, 0);
        navigate(`/productDetails/${id}`);
    }, [navigate]);

    if (loading) {
        return (
            <div className={styles.loaderContainer}>
                <HashLoader color={'#00796b'} loading={loading} size={58} />
            </div>
        );
    }

    return (
      <div>
        <h1 className={styles.hotSellsheading}>Hot Sells</h1>
        <h3 className={styles.hotSellsSubheading}>
          Get the best deals on the best products
        </h3>
        <div className={styles.container}>
          {products.map(({ imgSrc, price, title, _id, Quantity, PNumber }) => (
            <div
              key={_id}
              onClick={() => handleNavigation(_id)}
              className={styles.maindiv}
            >
              <div className={styles.imageContainer}>
                {!Quantity && (
                  <span className={styles.outOfStockBadge}>Out of Stock</span>
                )}
                <img src={imgSrc} alt={title} className={styles.image} loading="lazy" />
              </div>
              <div className={styles.details}>
                <h3 className={styles.title}>{title}</h3>
                <p className={styles.price}>₹{price}</p><br />
                {PNumber == 13 || PNumber == 9 || PNumber == 10 ? (
                  <p className={styles.preBooking}>
                    <span>Pre-Booking Available</span>
                  </p>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
};

export default HotSells;
