import React, { useState, useContext } from 'react';
import axios from 'axios';
import styles from './AddProduct.module.css'; // Import the CSS module
import productContext from '../Context/ProductContext/ProductContext';

const AddProduct = () => {
    const context = useContext(productContext);
    // console.log(context.url);
    const url = context.url;
    const [product, setProduct] = useState({
        title: "",
        description: "",
        price: 0,
        discountPrice: "",
        category: "",
        qty: 1,
        imgSrc1: "",
        imgSrc2: "",
        imgSrc3: "",
        imgSrc4: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProduct(prevProduct => ({
            ...prevProduct,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // const url = "https://darsh-backend.onrender.com/api/product/addproduct";

        try {
            const response = await axios.post(url, product);
            console.log('Product submitted:', response.data);
            // Optionally, reset form after submission
            setProduct({
                title: "",
                description: "",
                price: "",
                discountPrice: "",
                category: "",
                qty: 1,
                imgSrc1: "",
                imgSrc2: "",
                imgSrc3: "",
                imgSrc4: ""
            });
        } catch (error) {
            console.error('Error submitting product:', error.message);
        }
    };

    return (
        <div className={styles.productForm}>
            <h2>Add New Product</h2>
            <form onSubmit={handleSubmit}>
                <div className={styles.formGroup}>
                    <label>Title:</label>
                    <input
                        type="text"
                        name="title"
                        value={product.title}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className={styles.formGroup}>
                    <label>Description:</label>
                    <textarea
                        name="description"
                        value={product.description}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className={styles.formGroup}>
                    <label>Price:</label>
                    <input
                        type="text"
                        name="price"
                        value={product.price}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className={styles.formGroup}>
                    <label>Discount Price:</label>
                    <input
                        type="text"
                        name="discountPrice"
                        value={product.discountPrice}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className={styles.formGroup}>
                    <label>Category:</label>
                    <input
                        type="text"
                        name="category"
                        value={product.category}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className={styles.formGroup}>
                    <label>Quantity:</label>
                    <input
                        type="number"
                        name="qty"
                        value={product.qty}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className={styles.formGroup}>
                    <label>Image URL 1:</label>
                    <input
                        type="text"
                        name="imgSrc1"
                        value={product.imgSrc1}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className={styles.formGroup}>
                    <label>Image URL 2:</label>
                    <input
                        type="text"
                        name="imgSrc2"
                        value={product.imgSrc2}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className={styles.formGroup}>
                    <label>Image URL 3:</label>
                    <input
                        type="text"
                        name="imgSrc3"
                        value={product.imgSrc3}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className={styles.formGroup}>
                    <label>Image URL 4:</label>
                    <input
                        type="text"
                        name="imgSrc4"
                        value={product.imgSrc4}
                        onChange={handleChange}
                        required
                    />
                </div>
                <button type="submit" className={styles.submitButton}>Submit</button>
            </form>
        </div>
    );
};

export default AddProduct;
