import React, { useState } from 'react';
import styles from './NewsPage.module.css'; 
const NewsPage = () => {
  const newsData = [
    {
      title: "BUSINESS WORLD NEWS",
      description: "Government Of India Certified Darsh Silk Sarees",
      imageUrl: "https://images.pexels.com/photos/12707148/pexels-photo-12707148.jpeg?auto=compress&cs=tinysrgb&w=400"
    },
    {
      title: "OUTLOOK SPOTLIGHT",
      description: "Darsh - A Brand Bringing Back The Grandeur Of The Handloom Industry",
      imageUrl: "https://images.pexels.com/photos/11205026/pexels-photo-11205026.jpeg?auto=compress&cs=tinysrgb&w=400"
    },
    {
      title: "ZEE NEWS INDIA",
      description: "Darsh offers readymade blouses that perfectly fit with your six yarder",
      imageUrl: "https://images.pexels.com/photos/2531734/pexels-photo-2531734.jpeg?auto=compress&cs=tinysrgb&w=400"
    },
    {
        title: "INDIA TODAY",
        description: "Darsh: The best saree shopping destination to meet the taste of every woman & every occasion",
        imageUrl: "https://images.pexels.com/photos/2784078/pexels-photo-2784078.jpeg?auto=compress&cs=tinysrgb&w=400"
    },
    {
        title: "ZEE BUSINESS NEWS",
        description: "Darsh - that brand with the widest range of Sarees",
        imageUrl: "https://images.pexels.com/photos/2723623/pexels-photo-2723623.jpeg?auto=compress&cs=tinysrgb&w=400"
    },
    {
        title: "NEWS18 INDIA",
        description: "Darsh offers readymade blouses that perfectly fit with your six yarder",
        imageUrl: "https://images.pexels.com/photos/7685572/pexels-photo-7685572.jpeg?auto=compress&cs=tinysrgb&w=400"
    },
    {
        title: "THE STATESMAN",
        description: "Darsh is trying to promote the ancient and rich Indian handloom tradition",
        imageUrl: "https://images.pexels.com/photos/9418783/pexels-photo-9418783.jpeg?auto=compress&cs=tinysrgb&w=400"
    },
  ];
    
  const [expandedIndex, setExpandedIndex] = useState(-1);

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? -1 : index);
  };

  return (
    <>
      <h1 className={styles.newsHeading}>Darsh Media</h1>
      <div className={styles.newsContainer}>
        {newsData.map((article, index) => (
          <div className={styles.newsCard} key={index}>
            <img src={article.imageUrl} alt={article.title} />
            <div className={styles.newsContent}>
              <h2>{article.title}</h2>
              {expandedIndex === index ? (
                <p>{article.description.substring(0, 50)}. Darsh Media presence is increasing everyday. It has become a best Saree brand for ethnic Sarees.
                    Below are given some of the recent media inclusion by Indian news sources</p>
              ) : (
                <p>{article.description}</p>
              )}
              <button className={styles.button} onClick={() => toggleExpand(index)}>
                {expandedIndex === index ? 'Read Less' : 'Read More'}
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default NewsPage;
