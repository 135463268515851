import React, { useContext, useEffect, useState, useRef } from 'react';
import styles from "./AllProduct.module.css";
import { useNavigate } from 'react-router-dom';
import productContext from '../Context/ProductContext/ProductContext';
import HashLoader from "react-spinners/HashLoader";

const AllProduct = () => {
    // console.log("Allsari.js");

    const context = useContext(productContext);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true); 
     const navigate = useNavigate();
    const productRefs = useRef([]);

    const loadingFun = async () => {
        if (products.length === 0) {
            // Only fetch if products are not available
            const response = await context.fetchProducts();
            // console.log(response.data.product);
            setProducts(response.data.product); 
            setLoading(false);  
        }
    }

    useEffect(() => {
        loadingFun();
    }, []);

    useEffect(() => {
        const observerOptions = {
            threshold: 0.1,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add(styles.visible);
                    observer.unobserve(entry.target);
                }
            });
        }, observerOptions);

        const currentRefs = productRefs.current;

        currentRefs.forEach((ref) => {
            if (ref) {
                observer.observe(ref);
            }
        });

        return () => {
            currentRefs.forEach((ref) => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, [products]);

    const toDetails = (id) => {
        window.scrollTo(0, 0);
        navigate(`/productDetails/${id}`);
    };

    // Loading state
    return (
      <div>
        {loading ? (
          <div className={styles.loaderContainer}>
            <HashLoader color={"#009578"} loading={loading} size={58} />
          </div>
        ) : (
          <div>
            <h1 className={styles.allSariHeading}>TRENDY COLLECTION</h1>

            <div className={styles.container}>
              {products.map(
                ({ imgSrc, price, title, _id, Quantity, PNumber }, index) => (
                  <div
                    key={_id}
                    onClick={() => toDetails(_id)}
                    className={styles.maindiv}
                    ref={(el) => (productRefs.current[index] = el)}
                  >
                    <div className={styles.image}>
                      {!Quantity && (
                        <span className={styles.outOfStockBadge}>
                          Out of Stock
                        </span>
                      )}
                      <img src={imgSrc} alt={title} loading="lazy" />
                    </div>
                    <div className={styles.details}>
                    {title}
                    <br />
                    <p>₹{price}</p>
                    <br />
                      {PNumber == 13 || PNumber == 9 || PNumber == 10 ? (
                        <p className={styles.preBooking}>
                          <span>Pre-Booking Available</span>
                        </p>
                      ) : null}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </div>
    );
};

export default AllProduct;
