import React from 'react';
import { Link } from 'react-router-dom';
import { GiHanger } from "react-icons/gi";
import styles from './Pagenotfound.module.css'; // Import the CSS module

const Pagenotfound = () => {
    return (
        <div className={styles.pnf}>
            <h3 className={styles.name}>
                <Link to="/" className={styles.pnfLink}><span>darshsaree.com </span></Link>
                Women Clothing Collection Online
            </h3>
            <GiHanger className={styles.pnfIcon} />
            <h1 className={styles.title}>We couldn't find any matches!</h1>
            <h2 className={styles.subtitle}>Please check the spelling or try searching something else.</h2>
            <Link to="/" className={styles.pnfBtn}>Go Back</Link>
        </div>
    );
};

export default Pagenotfound;
