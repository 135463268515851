import React from 'react';
import { useSpring, animated } from 'react-spring';
import styles from './TermsAndConditions.module.css'; // Import the CSS module

const TermsAndConditions = () => {
    const fadeIn = useSpring({
        from: { opacity: 0, transform: 'translateY(20px)' },
        to: { opacity: 1, transform: 'translateY(0)' },
        config: { duration: 1000 },
    });

    return (
        <div className={styles.termsContainer}>
            <animated.h1 style={fadeIn}>Terms & Condition</animated.h1>
            <animated.div style={fadeIn} className={styles.termsSection}>
                <p>Welcome to Darsh and Conditions page. Please read these terms carefully before using our service.</p>
            </animated.div>
            <animated.div style={fadeIn} className={styles.termsSection}>
                <h2>1. Acceptance of Terms: </h2>
                <p>By accessing or using our service, you agree to be bound by these terms. If you do not agree with any part of the terms, you may not use our service.</p>
            </animated.div>
            <animated.div style={fadeIn} className={styles.termsSection}>
                <h2>2. Changes to Terms: </h2>
                <p>We reserve the right to modify or replace these terms at any time. It is your responsibility to check this page periodically for changes. Your continued use of the service after any changes constitutes acceptance of those changes.</p>
            </animated.div>
            <animated.div style={fadeIn} className={styles.termsSection}>
                <h2>3. Use of Service: </h2>
                <p>You agree to use the service only for lawful purposes and in accordance with these terms. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your account.</p>
            </animated.div>
            <animated.div style={fadeIn} className={styles.termsSection}>
                <h2>4. Termination: </h2>
                <p>We may terminate or suspend your account and bar access to the service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the terms.</p>
            </animated.div>
            <animated.div style={fadeIn} className={styles.termsSection}>
                <h2>5. Governing Law: </h2>
                <p>These terms shall be governed and construed in accordance with the laws of the jurisdiction in which our company, Darsh, is based, without regard to its conflict of law provisions.</p>
            </animated.div>
            <animated.div style={fadeIn} className={styles.termsSection}>
                <h2>6. Changes to Terms: </h2>
                <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time.</p>
            </animated.div>
            <animated.div style={fadeIn} className={styles.termsSection}>
                <h2>7. Contact Information: </h2>
                <p>If you have any questions about these Terms, please contact us at: darshsathi@gmail.com or +91 9907804710</p>
            </animated.div>
        </div>
    );
};

export default TermsAndConditions;
