import React from 'react';
import styles from './PaymentOptions.module.css';
import { SiRazorpay } from "react-icons/si";
import { BsBank2 } from "react-icons/bs";
import { IoCardOutline } from "react-icons/io5";
import { BsQrCodeScan } from "react-icons/bs";
import { IoMdWallet } from "react-icons/io";
import { SiPaytm } from "react-icons/si";
import { FaGooglePay } from "react-icons/fa";
import { FaCcAmazonPay } from "react-icons/fa6";




const PaymentOptions = () => {
  return (
    <div className={styles.paymentOptionsPage}>
      <div className={styles.paymentHeader}>
        <h1>Payment Options</h1>
        <div className={styles.icon}><SiRazorpay /></div>
        <h4>Razorpay</h4>
        <p>Darsh offers a variety of payment methods for your convenience with Razorpay on Darsh!</p>
      </div>

      <div className={styles.paymentMethods}>
        <div className={`${styles.paymentCard} ${styles.bounceIn}`}>
          <div className={styles.icon}><IoCardOutline /></div>
          <h2>Credit/Debit Cards</h2><br />
          <p>Pay securely using all major credit and debit cards. Our secure gateway ensures your transaction is processed safely with industry-standard encryption.</p>
        </div>

        <div className={`${styles.paymentCard} ${styles.bounceIn} ${styles.delay1s}`}>
          <div className={styles.icon}><BsQrCodeScan /></div>
          <h2>UPI Payments</h2><br />
          <p>Use your favorite UPI (Unified Payments Interface) app for quick and hassle-free payments. Simply scan the QR code and authorize the payment using your UPI app.</p>
        </div>

        <div className={`${styles.paymentCard} ${styles.bounceIn} ${styles.delay2s}`}>
          <div className={styles.icon}><BsBank2/></div>
          <h2>Net Banking</h2><br />
          <p>Transfer funds directly from your bank account using Razorpay’s integrated net banking feature. Select your bank, log in, and authorize the payment securely.</p>
        </div>

        <div className={`${styles.paymentCard} ${styles.bounceIn} ${styles.delay3s}`}>
          <div className={styles.icon}><IoMdWallet /></div>
          <h2>Wallets</h2><br />
          <p>Pay using popular digital wallets like Paytm, Mobikwik, and others. Simply choose your wallet, log in, and complete the transaction effortlessly.</p>
        </div>

        <div className={`${styles.paymentCard} ${styles.bounceIn} ${styles.delay4s}`}>
          <div className={styles.icon}><SiPaytm /></div>
          <h2>Paytm</h2><br />
          <p>Use the Paytm app to complete your payment. Log in to your Paytm account and pay securely with a few taps on your mobile device. Pay using Paytm app.</p>
        </div>
        <div className={`${styles.paymentCard} ${styles.bounceIn} ${styles.delay5s}`}>
          <div className={styles.icon}><FaGooglePay/></div>
          <h2>Google Pay</h2><br />
          <p> Pay using the Google Pay app. Choose Google Pay as your payment method, authorize the payment using your phone, and the transaction is done in seconds. Pay using Google Pay app.</p>
        </div>
        <div className={`${styles.paymentCard} ${styles.bounceIn} ${styles.delay6s}`}>
          <div className={styles.icon}><FaCcAmazonPay/></div>
          <h2>Amazon Pay</h2><br />
          <p>Use Amazon Pay to complete your payment. Simply log in with your Amazon account and use the funds from your Amazon Pay balance or linked payment methods. Pay using Amazon app.</p>
        </div>

        

      </div>
    </div>
  );
};

export default PaymentOptions;
