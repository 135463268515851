import React from "react";
import styles from "./TopCatagory.module.css";

const TopCatagory = () => {
  const categories = [
    {
      id: 1,
      title: "Banarasi",
      img:   'https://ik.imagekit.io/jkmpwfudl/Darsh/G.jpg?updatedAt=1731760405740',
    },
    {
      id: 2,
      title: "Cottton Slik",
      img:   'https://ik.imagekit.io/jkmpwfudl/Darsh/B.jpg?updatedAt=1731760405896',
    },
    {
      id: 3,
      title: "Dhakal",
      img:  'https://ik.imagekit.io/jkmpwfudl/Darsh/F.jpg?updatedAt=1731760405716',
    },
    {
      id: 4,
      title: "Cgarepe",
      img:   'https://ik.imagekit.io/jkmpwfudl/Darsh/E.jpg?updatedAt=1731760405748',
    },
    {
        id: 5,
        title: "Jamdani",
        img:   'https://ik.imagekit.io/jkmpwfudl/Darsh/A.jpg?updatedAt=1731760405568',
      },
      {
        id: 6,
        title: "Bengali saree",
        img:     'https://ik.imagekit.io/jkmpwfudl/Darsh/DARSH%20WEB%20BANNER.jpg?updatedAt=1731808248359',

      },
  ];

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>TOP CATEGORIES</h2><br/>
      {/* <p className={styles.subheading}>
        Browse a diverse range of categories, from chic sarees to versatile
        accessories. Elevate your style today!
      </p> */}
      <div className={styles.categories}>
        {categories.map((category) => (
          <div className={styles["category-card"]} key={category.id}>
            <div className={styles["category-image"]}>
              <img src={category.img} alt={category.title} />
            </div>
            <div className={styles["category-title"]}>{category.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopCatagory;
