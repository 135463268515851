import React, { useContext, useEffect, useState, useCallback, useRef } from 'react'
import styles from "./ProductDetailsPage.module.css";
import { FaPlus, FaMinus, FaShippingFast, FaWhatsapp } from "react-icons/fa";
import { useParams, useNavigate } from 'react-router-dom';
import productContext from '../Context/ProductContext/ProductContext';
import axios from 'axios';
import HashLoader from "react-spinners/HashLoader";
import BeatLoader from "react-spinners/BeatLoader";
import Allsari from '../Body/Allsari';
import { GiReturnArrow } from "react-icons/gi";
import { BsQrCodeScan } from "react-icons/bs";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import alertSound from '../assets//mixkit-select-click-1109.wav';
import { FaShoppingCart } from "react-icons/fa";
import { MdOutlineShoppingCartCheckout } from "react-icons/md";




const ProductDetailsPage = () => {
    // console.log("ProductDetailsPage.js");

    const [loading, setLoading] = useState(true);

    const audioRef = useRef(new Audio(alertSound));
    const [isRedirecting, setIsRedirecting] = useState(false);

    const auth = useContext(productContext);
    const token = auth.authinticated;
    const navigate = useNavigate();
    const cart = () => {
        window.scrollTo(0, 0);
        navigate("/cart")
    }


    const toDetails = (id) => {
        window.scrollTo(0, 0);
        navigate(`/productDetails/${id}`);
    };

    // console.log("token", token)
    const [quantity, setQuantity] = useState(1);
    const [productDetails, setProductDetails] = useState({
        title: "",
        description: "",
        price: 0,
        Quantity: 0,
        PNumber: 0,
        category: "",
        date: "",
        imgSrc: "",
        qty: 0
    });
    const [cartDetails, setCartDetails] = useState({
        productId: "",
        title: "",
        price: 0,
        qty: 0,
        imgSrc: "",
    });




    const { id } = useParams();
    const url = auth.url;
    // console.log(url)
    // Fetch product details
    const fetchProductDetails = useCallback(async () => {
        try {
            const response = await axios.get(`${url}/api/product/${id}`);
            // console.log(response.data.product);
            const { title, description, price, Quantity, PNumber, category, date, imgSrc } = response.data.product;
            setProductDetails({ title, description, price, Quantity, PNumber, category, date, imgSrc });
        } catch (error) {
            console.log(error.message);
            toast.error('Failed to fetch product details!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }, [id, url]);

    useEffect(() => {
        const fetchDetails = async () => {
            await fetchProductDetails();
            setLoading(false);
        };
        fetchDetails();
    }, [fetchProductDetails]);
    // console.log(productDetails.price)

    const [calPrice, setCalPrice] = useState(0);

    const [isPlusActive, setIsPlusActive] = useState(true); // Track the active button
    // Function to increase quantity
    const increaseQty = () => {
        setQuantity(prevQty => prevQty + 1);
        setIsPlusActive(true); // Set plus as the active button
    }

    // Function to decrease quantity
    const decreaseQty = () => {
        setQuantity(prevQty => (prevQty > 1 ? prevQty - 1 : 1));
        setIsPlusActive(false); // Set minus as the active button
    }


    // Update cartDetails when productDetails or quantity changes
    useEffect(() => {
        setCalPrice(quantity * productDetails.price)
        const { title, imgSrc } = productDetails;
        setCartDetails({
            productId: id,
            title,
            price: calPrice,
            qty: quantity,
            imgSrc
        });
    }, [productDetails, quantity, id, calPrice]);

    // console.log(cartDetails);

    // Send cartDetails to backend http://localhost:2000/api/cart/addToCart
    const sendCartDetails = async () => {
        try {
            if (!token) {
                toast.warn('Please log in to add items to the cart.', {
                    position: "top-right",
                    autoClose: 3000,
                });
                navigate("/login");
                return;
            }
            await axios.post(`${url}/api/cart/addToCart`, cartDetails, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Auth': token
                }
            });

            audioRef.current.play();

            // console.log('Product added successfully, showing toast...');
            toast.success('Product added to cart successfully!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            setIsRedirecting(true); // Start redirecting
            setTimeout(() => {
                cart(); // Navigate to cart after a short delay
            }, 1500);
        } catch (error) {
            // Show error toast message
            console.error('Error adding product:', error);
            toast.error('Failed to add product to cart!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };



    // get all related products
    // useEffect(() => {
    //     auth.fetchProducts(); // Fetch products only once when the component mounts
    // }, [auth]);

    const sariData = auth.products.filter(product => product.category === productDetails.category);
    return (
      <div>
        <ToastContainer />
        <audio ref={audioRef} src={alertSound} />
        {loading ? (
          <div className={styles.loaderContainer}>
            <HashLoader color={"#009578"} loading={loading} size={58} />
          </div>
        ) : (
          <div>
            <div className={styles.outer}>
              {/* img div */}
              <div className={styles.imageDiv}>
                <div className={styles.mainImg}>
                  <img
                    className={styles.displaiedImg}
                    src={productDetails.imgSrc}
                    alt="Product"
                  />
                </div>
                {/* <div className={styles.subImgDiv}>
                                <img onClick={() => { setImgSrc(productDetails.imgSrc1) }} className={styles.subImage} src={productDetails.imgSrc1} alt="" />
                                <img onClick={() => { setImgSrc(productDetails.imgSrc2) }} className={styles.subImage} src={productDetails.imgSrc2} alt="" />
                                <img onClick={() => { setImgSrc(productDetails.imgSrc3) }} className={styles.subImage} src={productDetails.imgSrc3} alt="" />
                                <img onClick={() => { setImgSrc(productDetails.imgSrc4) }} className={styles.subImage} src={productDetails.imgSrc4} alt="" />
                            </div> */}
              </div>
              {/* details div */}
              <div className={styles.detailsDiv}>
                {/* title div */}
                <div className={styles.productNameDiv}>
                  <h2 className={styles.productName}>{productDetails.title}</h2>
                  {/* verified */}

                  <img
                    src="/IMG/verified.png"
                    alt="verified"
                    className={styles.verifiedImg}
                  />
                </div>

                {/* price div */}
                <div className={styles.descriptionDiv}>
                  <h2>Price : </h2>
                  <p style={{ fontSize: "20px" }}>{productDetails.price}</p>
                </div>
                {/* description div */}
                <div className={styles.descriptionDiv}>
                  <h2>Description : </h2>
                  <p>{productDetails.description}</p>
                </div>
                {/* quantity div */}
                <div className={styles.quantityDiv}>
                  <h2>Quantity :</h2>
                  <div className={styles.incDecBtnDiv}>
                    <div
                      className={`${styles.button} ${
                        !isPlusActive ? styles.activeButton : ""
                      }`}
                      onClick={decreaseQty}
                    >
                      <FaMinus className={styles.plusminus} />
                    </div>
                    <div className={styles.button}>{quantity}</div>
                    <div
                      className={`${styles.button} ${isPlusActive ? styles.activeButton : ""}`}
                      onClick={increaseQty}
                    >
                      <FaPlus className={styles.plusminus} />
                    </div>
                  </div>
                </div>
                {/* stock div */}
                <div className={styles.descriptionDiv}>
                  {productDetails.PNumber == 13 ||
                  productDetails.PNumber == 9 ||
                  productDetails.PNumber == 10 ? (
                    <h2> PRE-BOOKING GOING ON WHATSAPP US FOR MORE DETAILS</h2>
                  ) : (
                    <h2></h2>
                  )}
                  {/* <h2>Stock : </h2>
                  <p style={{ fontSize: "20px" }}>{productDetails.Quantity}</p> */}
                </div>
                {/* size div */}
                {/* <div className={styles.quantityDiv}>
                            <h2>Size :</h2>
                            <div className={styles.incDecBtnDiv}>
                                <div className={styles.button}>S</div>
                                <div className={styles.button}>M</div>
                                <div className={styles.button}>L</div>
                            </div>
                        </div> */}
                {/* Add to Cart Button */}
                <div className={styles.buttonContainer}>
                  {/* go to cart */}
                  <button className={styles.wishlistBtn} onClick={cart}>
                    <i className="fa fa-heart">
                      <MdOutlineShoppingCartCheckout />
                    </i>{" "}
                    <p>GO TO CART</p>
                  </button>
                  {/* Add to Bag Button */}
                  <button
                    className={styles.addToBagBtn}
                    onClick={sendCartDetails}
                    disabled={productDetails.Quantity ? false : true}
                  >
                    <i className="faicon">
                      <FaShoppingCart />
                    </i>
                    {productDetails.Quantity ? (
                      <p>ADD TO CART</p>
                    ) : (
                      <p>OUT OF STOCK</p>
                    )}
                  </button>
                </div>

                {/* Loading Section Under Add to Cart */}
                {isRedirecting && (
                  <div className={styles.redirectLoader}>
                    <BeatLoader
                      color={"#009578"}
                      loading={isRedirecting}
                      size={10}
                    />
                    <p>Redirecting to cart...</p>
                  </div>
                )}

                {/* New Details Section under Add to Cart */}
                <div className={styles.detailsSection}>
                  <div
                    className={styles.detailItem}
                    onClick={() => {
                      navigate("/ShippingAndDelivery");
                      window.scrollTo(0, 0);
                    }}
                  >
                    <FaShippingFast className={styles.icon} />
                    <p>Get it delivered in 5-7 days</p>
                  </div>
                  <div
                    className={styles.detailItem}
                    onClick={() => {
                      navigate("/PaymentOptions");
                      window.scrollTo(0, 0);
                    }}
                  >
                    <BsQrCodeScan className={styles.icon} />
                    <p>Online Payment only</p>
                  </div>
                  <div
                    className={styles.detailItem}
                    onClick={() => {
                      navigate("/CancellationandRefund");
                      window.scrollTo(0, 0);
                    }}
                  >
                    <GiReturnArrow className={styles.icon} />
                    <p>Return/Exchange within 3 days</p>
                  </div>
                </div>

                {/* Contact Us */}
                <div className={styles.contactSection}>
                  <h3>Contact Us</h3>
                  <p>Get in touch directly on WhatsApp!</p>

                  <a
                    href="https://wa.link/wdfj7f"
                    target="_blank"
                    rel="noreferrer"
                    className="whatsappLink"
                  >
                    <button className={styles.whatsappButton}>
                      <FaWhatsapp className={styles.whatsappIcon} />
                      START CHAT
                    </button>
                  </a>
                </div>
              </div>
              {/* <div style={{ height: "1000px" }}></div> */}
            </div>

            {/* Existing Related Products Section */}
            <div className={styles.outerDiv}>
              <div className={styles.formDiv}>
                <h1 className={styles.date}>You may also like</h1>
              </div>
              {sariData.length > 0 ? (
                <div className={styles.container}>
                  {sariData.map((elem) => {
                    const { imgSrc, title, _id } = elem;
                    return (
                      <div
                        onClick={() => {
                          toDetails(_id);
                        }}
                        key={_id}
                        className={styles.maindiv}
                      >
                        <div className={styles.image}>
                          <img src={imgSrc} alt={title} />
                        </div>
                        <div className={styles.details}>{title}</div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <Allsari />
              )}
            </div>
          </div>
        )}
      </div>
    );
}

export default ProductDetailsPage
