import React from 'react';
import { useSpring, animated } from 'react-spring';
import styles from './TermsAndConditions.module.css'; // Import the CSS module

const PrivacyPolicy = () => {
    const fadeIn = useSpring({
        from: { opacity: 0, transform: 'translateY(20px)' },
        to: { opacity: 1, transform: 'translateY(0)' },
        config: { duration: 1000 },
    });

    return (
        <div className={styles.termsContainer}>
            <animated.h1 style={fadeIn}>Privacy Policy</animated.h1>
            <animated.div style={fadeIn} className={styles.termsSection}>
                <p>Welcome to our Privacy Policy page. Your privacy is critically important to us.</p>
            </animated.div>
            <animated.div style={fadeIn} className={styles.termsSection}>
                <h2>Information We Collect: </h2>
                <p>We collect various types of information in connection with the services we provide, including:<br /></p>
                <p>I. Personal identification information</p><br />
                <p>II. Usage Data</p><br />
                <p>III. Cookies and tracking data</p>
            </animated.div>
            <animated.div style={fadeIn} className={styles.termsSection}>
                <h2>How We Use Your Information: </h2>
                <p>The information we collect is used for various purposes such as:</p><br />
                <p>I. To provide and maintain our service.</p><br />
                <p>II. To notify you about changes to our service.</p><br />
                <p>III. To provide customer support.</p><br />
                <p>IV. To gather analysis or valuable information so that we can improve our service.</p><br />
            </animated.div>
            <animated.div style={fadeIn} className={styles.termsSection}>
                <h2>Security of Your Data: </h2>
                <p>We take the security of your data seriously and implement a variety of measures to protect it.</p>
            </animated.div>
            <animated.div style={fadeIn} className={styles.termsSection}>
                <h2>Changes to This Privacy Policy: </h2>
                <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
            </animated.div>
            <animated.div style={fadeIn} className={styles.termsSection}>
                <h2>Contact Information: </h2>
                <p>If you have any questions about these Terms, please contact us at: darshsathi@gmail.com or +91 9907804710</p>
            </animated.div>
        </div>
    );
};

export default PrivacyPolicy;
