import React from 'react';
import FirstPage from './FirstPage';
import Allsari from './Allsari'; 
import WhyChooseUs from './WhyChooseUs';
import TopCatagory from './TopCatagory';
import ReviewsSection from './ReviewsSection';
import ReelsCarousel from './ReelsCarousel';



const Body = () => {
    
    return (
        <main className="body-container">
            {/* First Page Section */}
            <section className="first-page-section">
                <FirstPage />
            </section>

            <section className="TopCatagory-section">
                <TopCatagory/> 
            </section>

            {/* Allsari Product Listing Section */}
            <section className="allsari-section">
                <Allsari /> 
            </section>
            
            {/* Carousel Section */}
            <section className="allsari-section">
            <ReelsCarousel />
            </section>


            {/* Reviews Section */}
            <section className="allsari-section">
                <ReviewsSection /> 
            </section>

            

            {/* Why Choose Us Section */}
            <section className="why-choose-us-section">
                <WhyChooseUs />
            </section>
        </main>
    );
};

export default Body;
