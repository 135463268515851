import React, { useContext, useEffect, useState } from 'react';
import styles from './Cart.module.css';
import productContext from '../Context/ProductContext/ProductContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import HashLoader from "react-spinners/HashLoader";


const Cart = () => {
    const [loading, setLoading] = useState(true);
    const [tprice, setTprice] = useState(0);
    const [isDisabled, setIsDisabled] = useState(false);
    const [error, setError] = useState("")
    const [address, setAddress] = useState({});

    // const [userId, setUserId] = useState(localStorage.getItem('userId') || '');
    const allData = useContext(productContext);
    const url = allData.url;
    // address of user 
    // console.log(address);
    const userId = localStorage.getItem('userId');
    // console.log(userId)
    const cartItems = allData.cartData;
    // console.log(cartItems);
    // console.log(tprice);
    const navigate = useNavigate();
    // api for remove product from cart
    const removeProductFromCart = async (id) => {
        try {
            setLoading(true);
            // console.log("object")
            await axios.delete(`${url}/api/cart/remove/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Auth': allData.authinticated
                }
            });
            // console.log(responce);
            await allData.fetchCartData();
            setLoading(false);

        } catch (error) {
            console.log(error.message);
        }
    };
    const clearcart = async () => {
        try {
            // console.log("object")
            await axios.delete(`${url}/api/cart/clearCart`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Auth': allData.authinticated
                }
            });
            // console.log(responce);
            allData.fetchCartData();
        } catch (error) {
            console.log(error.message);
        }
    };

    const toDetails = (id) => {
        window.scrollTo(0, 0);
        navigate(`/productDetails/${id}`);
    }
    useEffect(() => {
        const cartData = async () => {
            if (allData.cartData.length === 0) {
                await allData.fetchCartData();
            }
            setLoading(false);
        }
        cartData();
        // allData.fetchUserAddress();
    }, []);

    useEffect(() => {
        // Ensure this only runs when allData.totalPrice changes
        setTprice(allData.totalPrice);
    }, [allData.totalPrice]);
    // console.log(allData.cartData)


    const fetchProductById = async (id, qty) => {
        try {
            const responce = await axios.get(`${url}/api/product/${id}`)
            // console.log("Quantity in Database", responce.data.product.Quantity)
            // console.log("Quantity in Cart", qty)

            const quantityNow = responce.data.product.Quantity - qty;
            // console.log("Quantity Now", quantityNow);
            // console.log(responce.data.product)
            const newQuantity = { ...responce.data.product, Quantity: quantityNow };
            // console.log(newQuantity);
            if (newQuantity) {
                try {
                    await axios.put(`${url}/api/product/${id}`, newQuantity)
                    // console.log(responce)/

                } catch (error) {
                    console.log(error.message)
                }
            } else {
                console.log("There no New Quantity");
            }

        } catch (error) {
            console.log(error);
        }
    }

    const fetchUserAddress = async () => {
        try {
            const token = localStorage.getItem('token');
            // http://localhost:2000/api/address/getAddressById
            const Address = await axios.get(`${url}/api/address/getAddressById`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Auth': token
                }
            });
            // console.log(Address);
            setAddress(Address.data.address);
        } catch (error) {
            console.log(error.message);
        }
    }

    useEffect(() => {
        fetchUserAddress();
    }, [])
    const decreaseQuantity = () => {
        // console.log(cartItems)

        const cartLength = cartItems.length;
        for (var i = 0; i < cartLength; i++) {
            // console.log(cartItems[i].productId);
            fetchProductById(cartItems[i].productId, cartItems[i].qty);
        }
    }

    // Handle the case when the cart is empty
    const handleEmptyCartMessage = () => {
        if (cartItems.length === 0) {
            return (
                <div className={styles.emptyCartMessage}>
                    <p>Your cart is empty. Please add some products to proceed with the order.</p>
                </div>
            );
        }
    };

    const handlePayment = async () => {
        if (address && address !== undefined && cartItems.length !== 0) {
            try {
                setIsDisabled(true);
                const orderResponce = await axios.post(`${url}/api/payment/checkout`, {
                    amount: tprice, cartItems: cartItems, usershipping: address, userId: userId
                });

                if (orderResponce) {
                    setIsDisabled(false);
                }

                const { amount, orderId } = orderResponce.data;

                var options = {
                    "key": "rzp_live_UVshYgtn2fCscP",
                    "amount": amount * 100,
                    "currency": "INR",
                    "name": "Darsh Web",
                    "description": "Darsh Web",
                    "order_id": orderId,
                    "handler": async function (response) {
                        try {
                            const confirmOrder = {
                                orderId: response.razorpay_order_id,
                                paymentId: response.razorpay_payment_id,
                                signature: response.razorpay_signature,
                                amount,
                                orderItems: cartItems,
                                userId,
                                userShipping: address,
                            };

                            const api = await axios.post(`${url}/api/payment/verify`, confirmOrder);
                            console.log(api)
                            if (api.data.success === true) {
                                decreaseQuantity();
                                clearcart();
                                navigate("/payment-confirmation", {
                                    state: {
                                        subtotal: tprice,
                                        taxRate: 2.5,
                                        fees: 0,
                                        address: address,
                                        orderId: response.razorpay_order_id,
                                    },
                                });
                            }
                        } catch (error) {
                            console.error(error);
                        }
                    },
                    "prefill": {
                        "name": "Darsh",
                        "email": "Darshweb@gmail.com",
                        "contact": "9339268656"
                    },
                    "theme": {
                        "color": "#3399cc"
                    }
                };
                var rzp = new window.Razorpay(options);
                rzp.open();
            } catch (error) {
                console.log(error);
            }
        } else {
            setError("Please add address and product");
        }
    };

    return (
        <div>
            {loading ? (
                <div className={styles.loaderContainer}>
                    <HashLoader color={'#009578'} loading={loading} size={58} />
                </div>
            ) : (
                <div className={styles.outerDiv}>
                    <div className={styles.innerDiv}>
                        <div className={styles.leftDiv}>
                            {/*  Display message if the cart is empty */}
                            {handleEmptyCartMessage()}
                            {allData.cartData.map((elem) => {
                                const { productId, title, price, qty, imgSrc } = elem;

                                return (
                                    <div key={productId} className={styles.productDiv} >
                                        <img className={styles.productImg} src={imgSrc} alt="img" onClick={() => toDetails(productId)} />
                                        <div className={styles.productDetails} onClick={() => toDetails(productId)}>
                                            <div className={styles.productName}>
                                                <p>{title}</p>
                                            </div>
                                            <div className={styles.quantity}>
                                                <p className={styles.p}>Quantity: {qty}</p>
                                            </div>
                                            <div className={styles.quantity}>
                                                <p className={styles.p}>Price: {price}</p>
                                            </div>
                                        </div>
                                        <div className={styles.editAndRemove}>
                                            <div onClick={() => { toDetails(productId) }} className={styles.editBtn}>
                                                <p className={styles.editText}>Edit</p>
                                            </div>
                                            <div onClick={() => { removeProductFromCart(productId) }} className={styles.removetBtn}>
                                                <p className={styles.removeText}>Remove</p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className={styles.rightDiv}>
                            <div className={styles.totalPriceDiv}>
                                <div className={styles.totalPriceDetails}>
                                    <p className={styles.priceDetails}>PRICE DETAILS</p>
                                </div>
                                <div className={styles.desPrice}>
                                    <div className={styles.price}>
                                        <p>Price:</p>
                                        <p>{tprice}</p>
                                    </div>
                                    <div className={styles.price}>
                                        <p>Delivery Charge:</p>
                                        <p>Free</p>
                                    </div>
                                    <div className={styles.price}>
                                        <p>Total Price:</p>
                                        <p>{tprice}</p>
                                    </div>
                                </div>
                                <div className={styles.checkOutDiv}>
                                    <button
                                        onClick={handlePayment}
                                        disabled={isDisabled}
                                        className={styles.checkOutBtn}>
                                        <p className={styles.removeText}>Place Order</p>
                                    </button>
                                </div>
                                {error &&
                                    <div className={styles.errorMsg}>
                                        {error}
                                    </div>

                                }
                            </div>

                            <div className={styles.messageDiv}>
                                <p className={styles.messageText}>
                                    After the Delivery of the Product, the unboxing time must be recorded otherwise the return policy will not be approved.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Cart;