import React from 'react';
import { useSpring, animated } from 'react-spring';
import styles from './TermsAndConditions.module.css';

const CancellationAndRefund = () => {
    const fadeIn = useSpring({
        from: { opacity: 0, transform: 'translateY(20px)' },
        to: { opacity: 1, transform: 'translateY(0)' },
        config: { duration: 1000 },
    });

    return (
        <div className={styles.termsContainer}>
            <animated.h1 style={fadeIn}>{`Return & Exchange Policy`}</animated.h1>
            <animated.div style={fadeIn} className={styles.termsSection}>
                <p>We strive to ensure your satisfaction with our products. Please review our cancellation and Return policy below.</p>
            </animated.div>
            <animated.div style={fadeIn} className={styles.termsSection}>
                <h2>1. Order Cancellation:  </h2>
                <p>You can cancel your order before it is shipped. Once the order is shipped, it cannot be canceled. To cancel an order, please contact our customer support team with your order details.</p>
            </animated.div>
            <animated.div style={fadeIn} className={styles.termsSection}>
                <h2>2. Refund Eligibility: </h2>
                <p>After the Delivery of the Product, the unboxing time must be recorded otherwise the return policy will not be approved. Refunds are only applicable for products that are defective or damaged upon delivery. To be eligible for a refund, you must notify us within 3 days of receiving the product. The product must be unused and in the same condition that you received it.</p>
            </animated.div>
            <animated.div style={fadeIn} className={styles.termsSection}>
                <h2>3. Refund Process: </h2>
                <p>In this refund Process you need to send a video of your Saree to our team, otherwise please contact our customer support team. We will review your request and, if approved, process the refund to your original payment method within 5-7 business days.</p>
            </animated.div>
            <animated.div style={fadeIn} className={styles.termsSection}>
                <h2>4. Non-Refundable Items: </h2>
                <p>Certain items are non-refundable, including but not limited to perishable goods, customized products, and items on sale. Please review the product description before making a purchase.</p>
            </animated.div>
            <animated.div style={fadeIn} className={styles.termsSection}>
                <h2>5. Exchanges: </h2>
                <p>We only replace items if they are defective or damaged. If you need to exchange an item for the same product, contact us within 3 days of receiving the product.</p>
            </animated.div>
            <animated.div style={fadeIn} className={styles.termsSection}>
                <h2>7. Contact Information: </h2>
                <p>If you have any questions about these Terms, please contact us at: darshsathi@gmail.com or +91 9907804710 </p>
            </animated.div>
        </div>
    );
};

export default CancellationAndRefund;
