import React, { useState, useRef } from "react";
import styles from "./ReviewsSection.module.css";
import { FaStar, FaStarHalfAlt, FaArrowLeft, FaArrowRight } from "react-icons/fa";

const ReviewsSection = () => {
  const [reviews, setReviews] = useState([
    {
      name: "Anisha Verma",
      image: "https://ik.imagekit.io/jkmpwfudl/Darsh/G.jpg?updatedAt=1731760405740",
      title: "Excellent Quality and Reasonable price",
      review: "The service was quick and quality is amazing.",
      rating: 4.5,
    },              
    {
      name: "Monalisa Das",
      image: "https://ik.imagekit.io/jkmpwfudl/Darsh/A.jpg?updatedAt=1731760405568",
      title: "Good product",
      review: "Product is really good.",
      rating: 4,
    },
    {
        name: "Sangita Dey",
        image: "https://ik.imagekit.io/jkmpwfudl/Darsh/E.jpg?updatedAt=1731760405748",
        title: "Simply awesome",
        review:
          "It's one of the best sarees within budget and quality is top class.",
        rating: 5,
      },
      {
        name: "Rohan Singh",
        image: "https://ik.imagekit.io/jkmpwfudl/Darsh/D.jpg?updatedAt=1731760405893",
        title: "Value for money",
        review: "Affordable price with top-notch quality.",
        rating: 4,
      },
      {
        name: "Priya Sharma",
        image: "https://ik.imagekit.io/jkmpwfudl/Darsh/F.jpg?updatedAt=1731760405716",
        title: "Loved it!",
        review: "Highly recommend this product to everyone.",
        rating: 5,
      },  
    {
        name: "Anisha Verma",
        image: "https://ik.imagekit.io/jkmpwfudl/Darsh/E.jpg?updatedAt=1731760405748",
        title: "Excellent Quality and Reasonable price",
        review: "The service was quick and quality is amazing.",
        rating: 2.5,
    },              
    {
        name: "Monalisa Das",
        image: "https://ik.imagekit.io/jkmpwfudl/Darsh/A.jpg?updatedAt=1731760405568",
        title: "Good product",
        review: "Product is really good.",
        rating: 3,
    },
    {
        name: "Anisha Verma",
        image: "https://ik.imagekit.io/jkmpwfudl/Darsh/E.jpg?updatedAt=1731760405748",
        title: "Excellent Quality and Reasonable price",
        review: "The service was quick and quality is amazing.",
        rating: 3.5,
    },              
    {
        name: "Monalisa Das",
        image: "https://ik.imagekit.io/jkmpwfudl/Darsh/F.jpg?updatedAt=1731760405716",
        title: "Good product",
        review: "Product is really good.",
        rating: 4.5,
    },
    {
      name: "Monalisa Das",
      image: "https://ik.imagekit.io/jkmpwfudl/Darsh/F.jpg?updatedAt=1731760405716",
      title: "Good product",
      review: "Product is really good.",
      rating: 4.5,
   },
  ]);

  const scrollRef = useRef(null);

  const scroll = (direction) => {
    const { current } = scrollRef;
    if (direction === "left") {
      current.scrollLeft -= 300;
    } else {
      current.scrollLeft += 300;
    }
  };


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newReview, setNewReview] = useState({
    name: "",
    image: "",
    title: "",
    review: "",
    rating: 0,
  });

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewReview({ ...newReview, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("New Review Data:", JSON.stringify(newReview, null, 2));  
    setReviews([...reviews, newReview]); 
    closeModal(); 
  };

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;

    return (
      <div className={styles.stars}>
        {Array.from({ length: fullStars }).map((_, index) => (
          <FaStar key={index} />
        ))}
        {halfStar && <FaStarHalfAlt />}
      </div>
    );
  };

  return (
    <section className={styles.reviewsSection}>
       <div className={styles.header}>
        <h2>What Our Customers Are Saying</h2>
        
        <button className={styles.reviewButton} onClick={openModal}>
          Share Your Experience
        </button>
      </div>
       <div className={styles.subHeader}>
        <p> Hear from our happy customers! We’ve displayed 10 top reviews just for you.</p>
      </div>

 
      <div className={styles.scrollWrapper}>
        <button
          className={`${styles.scrollButton} ${styles.leftButton}`}
          onClick={() => scroll("left")}
        >
          <FaArrowLeft />
        </button>

        <div className={styles.reviewsContent} ref={scrollRef}>
          {reviews.map((review, index) => (
            <div key={index} className={styles.card}>
              {renderStars(review.rating)}
              <h4 className={styles.title}>{review.title}</h4>
              <p className={styles.review}>{review.review}</p>
              <div className={styles.userInfo}>
                <img
                  src={review.image || "https://via.placeholder.com/50"}
                  alt={review.name}
                  className={styles.userImage}
                />
                <p className={styles.userName}>{review.name}</p>
              </div>
            </div>
          ))}
        </div>
        <button
          className={`${styles.scrollButton} ${styles.rightButton}`}
          onClick={() => scroll("right")}
        >
          <FaArrowRight />
        </button>
      </div>

       {isModalOpen && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <h3>Write a Review</h3>
            <form onSubmit={handleSubmit} className={styles.reviewForm}>
              <input
                type="text"
                name="name"
                placeholder="Your Name"
                value={newReview.name}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="image"
                placeholder="Image URL"
                value={newReview.image}
                onChange={handleChange}
              />
              <input
                type="text"
                name="title"
                placeholder="Review Title"
                value={newReview.title}
                onChange={handleChange}
                required
              />
              <textarea
                name="review"
                placeholder="Your Review"
                value={newReview.review}
                onChange={handleChange}
                required
              />
              <input
                type="number"
                name="rating"
                placeholder="Rating (0-5)"
                min="0"
                max="5"
                step="0.5"
                value={newReview.rating}
                onChange={handleChange}
                required
              />
              <div className={styles.formButtons}>
                <button type="button" onClick={closeModal}>
                  Cancel
                </button>
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </section>
  );
};

export default ReviewsSection;
