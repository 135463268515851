import React, { useEffect, useState } from 'react';
import { CiCircleChevUp } from 'react-icons/ci';
import { FiPhoneCall, FiMail, FiX } from 'react-icons/fi';
import { FaWhatsapp } from 'react-icons/fa';
import styles from './GoToTop.module.css';
import { LuMessagesSquare } from "react-icons/lu";

const GoToTop = () => {
  const [visible, setVisible] = useState(false);
  const [contactVisible, setContactVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      setVisible(window.pageYOffset > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const toggleContactButtons = () => {
    setContactVisible((prev) => !prev);
  };

  return (
    <>
      {visible && (
        <div className={styles.wrapper}>
          <div
            className={`${styles.contactBtn} ${contactVisible ? styles.rotate : ''}`}
            onClick={toggleContactButtons}
            aria-label={contactVisible ? 'Close contact options' : 'Open contact options'}
          >
            {contactVisible ? <FiX className={styles.icon} /> : <LuMessagesSquare className={styles.icon} />}
          </div>

          {contactVisible && (
            <div className={styles.contactOptions}>
              <a href="tel:9883277103" className={`${styles.contactOption} ${styles.opt1}`} aria-label="Call">
                <FiPhoneCall className={styles.icon} />
              </a>
              <a
                href="https://wa.link/xhc8o4"
                target="_blank"
                rel="noopener noreferrer"
                className={`${styles.contactOption} ${styles.opt2}`}
                aria-label="WhatsApp"
              >
                <FaWhatsapp className={styles.icon} />
              </a>
              <a href="mailto:darshweb2004@gmail.com" className={`${styles.contactOption} ${styles.opt3}`} aria-label="Email">
                <FiMail className={styles.icon} />
              </a>
            </div>
          )}

          <div
            className={styles.topBtn}
            onClick={scrollToTop}
            aria-label="Scroll to top"
          >
            <CiCircleChevUp className={styles.icon} />
          </div>
        </div>
      )}
    </>
  );
};

export default GoToTop;
