import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { motion } from 'framer-motion';
import { FaCopy, FaBoxOpen } from 'react-icons/fa';
import HashLoader from "react-spinners/HashLoader";
import axios from 'axios';
import productContext from '../Context/ProductContext/ProductContext';
import styles from './OrdersPage.module.css';
import { IoCheckmarkDoneCircle } from "react-icons/io5";


const OrdersPage = () => { 
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [copiedId, setCopiedId] = useState(null);
    const [error, setError] = useState('');
    const navigate = useNavigate(); 
    console.log(orders);
    

    const navigateProductDetails = (id) => {
      window.scrollTo(0, 0);
      navigate(`/productDetails/${id}`);
  };
    const auth = useContext(productContext);
    const url = auth.url;

    useEffect(() => {
        const fetchOrders = async () => {
          setLoading(true);
          try {
            const response = await axios.get(`${url}/api/payment/getOrderById`, {
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Auth': auth.authinticated,
              },
            });
            
            const orderData = response.data;
            setOrders(orderData);
          } catch (err) {
            setError('Failed to load orders. Please try again later.');
          } finally {
            setLoading(false);
          }
        };
        fetchOrders();
    }, [url, auth.authinticated]);
    console.log(orders);
    
    const handleCopy = (trackingId) => {
        if (trackingId && navigator.clipboard && navigator.clipboard.writeText) {
          navigator.clipboard.writeText(trackingId)
            .then(() => {
              setCopiedId(trackingId);
              setTimeout(() => setCopiedId(null), 2000);
            })
            .catch(() => setError('Copy failed. Try again.'));
        } else {
          alert("Clipboard copy is not supported in this browser or environment.");
        }
    };

    const handleTrackerLink = () => {
        window.open('https://www.delhivery.com/tracking', '_blank');
    };

    if (!Array.isArray(orders)) {
        return <p>Data format error: Orders should be an array.</p>;
    }

    if (loading) {
        return (
          <div className={styles.loaderContainer}>
            <HashLoader color={'#009578'} loading={loading} size={58} />
          </div>
        );
    }

    return (
      <div className={styles.container}>
        <div>
          <h2 className={styles.header}>My Orders</h2>
        </div>
        <br />
        {error && <p className={styles.error}>{error}</p>}

        {orders.length === 0 ? (
          <motion.div
            className={styles.noOrders}
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.6, ease: "easeOut" }}
          >
            <FaBoxOpen className={styles.icon} />
            <h3 className={styles.noOrdersTitle}>No Orders Found</h3>
            <p className={styles.message}>
              Start shopping to see your orders here!
            </p>
            <motion.div
              className={styles.noOrdersBorder}
              initial={{ width: "0%" }}
              animate={{ width: "100%" }}
              transition={{ duration: 1.2, ease: "easeInOut" }}
            />
          </motion.div>
        ) : (
          <motion.div
            className={styles.orderList}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, ease: "easeOut" }}
          >
            {orders.map((order) => (
              <div key={order.id} className={styles.orderGroup}>
                <div className={styles.orderHeader}>
                  <h3>Order id:{order._id} </h3>
                  <p>Order Date: {formatDate(order.orderDate)}</p>
                </div>
                {order.orderItems.map((product) => (
                  <div
                    key={product._id}
                    className={styles.orderItem}
                    onClick={() => navigateProductDetails(product.productId)}
                  >
                    <img
                      src={product.imgSrc}
                      alt={product.title}
                      className={styles.image}
                    />
                    <div className={styles.orderDetails}>
                      <h3 className={styles.productTitle}>{product.title}</h3>
                      <div className={styles.row}>
                        <p>Quantity: {product.qty};</p>
                        <p className={styles.price}>Price: ₹{product.price}</p>
                      </div>
                    </div>
                  </div>
                ))}
                <div className={styles.row1}>
                  <p className={styles.deliveryStatus}>
                    {order.payStatus === "paid" ? <span><IoCheckmarkDoneCircle/>Paid</span>: "Payment Pending"}
                  </p>
                  {order.status !== "Delivered" && (
                    <p className={styles.estimatedDate}>
                      Estimated delivery:{" "}
                      {getEstimatedDelivery(order.orderDate)}
                    </p>
                  )}
                </div>
                <div className={styles.row1}>
                  {order.trackingId ? (
                    <button
                      className={styles.reviewButton}
                      onClick={() => handleCopy(order.trackingId)}
                    >
                      <FaCopy className={styles.icon1} />
                      Copy Tracking ID: {order.trackingId}
                    </button>
                  ) : (
                    <button className={styles.reviewButton}>
                      <span className={styles.comingSoon}>
                        Tracking ID Coming Soon
                      </span>
                    </button>
                  )}
                  {copiedId === order.trackingId && <span>Copied!</span>}
                  {order.trackingId && (
                    <button
                      className={styles.reviewButton}
                      onClick={handleTrackerLink}
                    >
                      Click to track (AWB No)
                    </button>
                  )}
                </div>
              </div>
            ))}
          </motion.div>
        )}
      </div>
    ); 
}; 

const getEstimatedDelivery = (orderDate) => {
  const date = new Date(orderDate);
  const estimatedDate = new Date(date.setDate(date.getDate() + 7));
  return estimatedDate.toLocaleDateString('en-IN', { day: '2-digit', month: 'short', year: 'numeric' });
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-IN', {
    weekday: 'short', 
    day: '2-digit', 
    month: 'short', 
    year: 'numeric'
  });
};

export default OrdersPage;
